import axios, {AxiosResponse} from 'axios';
import {auth} from "../firebase";

const isDev = parseInt(process.env?.REACT_APP_DEV ?? "0") == 1;

export const api = axios.create({
  withCredentials: true,
  ...(isDev ? {baseURL: process.env.REACT_APP_DEV_API_BASE_URL} : {}),
});

export const getAuthorization = async (): Promise<{Authorization: string}> => {
  return { Authorization: await auth.currentUser?.getIdToken() || ""}
}