import React from "react";

export function formatGptTextToJSX(text: string): JSX.Element {
  const lines = text.split('\n');
  const elements = [];
  let listItems: JSX.Element[] = [];
  let insideList = false;

  lines.forEach((line, index) => {
    const trimmedLine = line.trim();
    const listItemMatch = trimmedLine.match(/^\d+\.\s+(.*)/);

    if (listItemMatch) {
      listItems.push(<li key={`li-${index}`}>{withBold(listItemMatch[1])}</li>);
      insideList = true;
    } else {
      if (insideList) {
        elements.push(
          <ol key={`ol-${index}`} className="list-decimal ml-5">
            {listItems}
          </ol>
        );
        listItems = [];
        insideList = false;
      }
      elements.push(
        <React.Fragment key={`line-${index}`}>
          {withBold(trimmedLine)}
          <br />
        </React.Fragment>
      );
    }
  });

  if (insideList) {
    elements.push(
      <ol key="ol-end" className="list-decimal ml-5 text-gray-400">
        {listItems}
      </ol>
    );
  }

  return <div>{elements}</div>;
}

export function withBold(text: string): JSX.Element {
  const boldPattern = /\*\*(.*?)\*\*/g;

  const parts: Array<string | JSX.Element> = [];
  let lastIndex = 0;
  let match: RegExpExecArray | null;

  while ((match = boldPattern.exec(text)) !== null) {
    if (match.index > lastIndex) {
      parts.push(text.substring(lastIndex, match.index));
    }

    parts.push(<strong key={match.index}>{match[1]}</strong>);

    lastIndex = boldPattern.lastIndex;
  }

  if (lastIndex < text.length) {
    parts.push(text.substring(lastIndex));
  }

  return <>{parts}</>;
}