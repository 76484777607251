import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { LanguageContext } from '../contexts/LanguageContext';
import { toast } from 'react-toastify';
import { IS_DOWNTIME } from '../constants';
import Downtime from './Downtime';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false); // Loading state for smooth feedback

  const authContext = useContext(AuthContext);
  const languageContext = useContext(LanguageContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const [bypassDowntime, setBypassDowntime] = useState(false);

  if (!languageContext) {
    throw new Error('LanguageContext must be used within a LanguageProvider');
  }

  const { language, setLanguage } = languageContext;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true); // Show loading state
    const res = await authContext?.login(email, password);
    setLoading(false); // Hide loading state

    if (res === "invalid-credentials") {
      toast.error(language === 'en' ? 'Wrong credentials' : 
        language === 'is' ? 'Rangar notanda upplýsingar' : 
                            'nieprawidłowe dane uwierzytelniające',
      { position: "top-center" });
    }
  };

  const changeLanguage = (lang: string) => {
    setLanguage(lang);
    setIsMenuOpen(false); // Close the menu when a language is selected
  };

  const getLanguageFlag = () => {
    switch (language) {
      case 'en':
        return '🇬🇧';
      case 'is':
        return '🇮🇸';
      case 'pl':
        return '🇵🇱';
      default:
        return '🌐';
    }
  };

  if (IS_DOWNTIME && !bypassDowntime) {
    return <Downtime bypassDowntime={() => setBypassDowntime(true)}/>;
  }

  return (
    <div className="min-h-screen flex items-center justify-center subtle-moving-gradient relative">
      <div className="absolute top-4 right-4" ref={menuRef}>
        <div className="relative">
          <button
            className="text-white focus:outline-none text-3xl"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {getLanguageFlag()}
          </button>
          {isMenuOpen && (
            <div className="absolute right-0 mt-2 w-56 bg-white rounded-md shadow-lg py-2 z-20"> 
              <button
                onClick={() => changeLanguage('en')}
                className="block w-full text-left px-6 py-3 text-lg text-gray-700 hover:bg-gray-100"
              >
                🇬🇧 English
              </button>
              <button
                onClick={() => changeLanguage('is')}
                className="block w-full text-left px-6 py-3 text-lg text-gray-700 hover:bg-gray-100"
              >
                🇮🇸 Íslenska
              </button>
              <button
                onClick={() => changeLanguage('pl')}
                className="block w-full text-left px-6 py-3 text-lg text-gray-700 hover:bg-gray-100"
              >
                🇵🇱 Polski
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="max-w-md w-full bg-opacity-80 bg-gray-900 p-8 rounded-lg shadow-lg transform transition-all duration-500 hover:scale-105">
        <div className="flex justify-center mb-6">
          <img src="/testlogo.png" alt="Logo" className="h-10 w-10 mr-3" />
        </div>
        <h2 className="text-3xl font-extrabold mb-6 text-white text-center tracking-wider">
          {language === 'en' ? 'Login' : language === 'is' ? 'Innskrá' : 'Zaloguj się'}
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-300">
              {language === 'en' ? 'Email' : language === 'is' ? 'Netfang' : 'Email'}
            </label>
            <input
              type="email"
              className="w-full px-4 py-2 mt-2 text-gray-900 border border-transparent rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent shadow-sm"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-300">
              {language === 'en' ? 'Password' : language === 'is' ? 'Lykilorð' : 'Hasło'}
            </label>
            <input
              type="password"
              className="w-full px-4 py-2 mt-2 text-gray-900 border border-transparent rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent shadow-sm"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className={`w-full py-2 px-4 ${
              loading ? 'bg-blue-400' : 'bg-blue-600 hover:bg-blue-700'
            } text-white font-bold rounded-lg transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 flex justify-center items-center`}
          >
            {loading ? (
              <svg
                className="animate-spin h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                ></path>
              </svg>
            ) : (
              language === 'en' ? 'Login' : language === 'is' ? 'Innskrá' : 'Zaloguj się'
            )}
          </button>
        </form>
        <p className="mt-6 text-center text-gray-400">
          {language === 'en' ? 'Don’t have an account?' : language === 'is' ? 'Ekki með reikning?' : 'Nie masz konta?'}{' '}
          <Link to="/register" className="text-blue-400 hover:underline transition-all duration-200">
            {language === 'en' ? 'Register' : language === 'is' ? 'Skrá' : 'Zarejestruj się'}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
