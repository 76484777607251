import {AxiosResponse} from 'axios';
import {api, getAuthorization} from "./index";
import { Timestamp } from 'firebase/firestore';

export type ChatResponse = AxiosResponse<{message: string}>

export type CreateAsaChatResponse = AxiosResponse<{chatId: string}>

export type DeleteAsaChatResponse = AxiosResponse<{success: boolean}>

export interface RestrictedAsaChat {
    id: string,
    userId: string,
    createdAt: number,
    updatedAt: number
}

export type ChatListResponse = AxiosResponse<{chats: RestrictedAsaChat[]}>

export interface AsaChatMessage {
    content: string,
    role: "user" | "assistant",
    timestamp: Timestamp
}
export type AsaChatMessagesResponse = AxiosResponse<{messages: AsaChatMessage[]}>


export interface AsaChat {
    id: string,
    userId: string,
    diagnosis: string,
    urgency: number,
    readByDoctor: boolean,
    createdAt: number,
    updatedAt: number,
    user: {
        id: string,
        email: string,
        fullname: string,
        role: string
        ssn: string,
    }
}
export type AsaChatByUrgencyResponse = AxiosResponse<{chats: AsaChat[], nextPage: string}>

export type AsaMarkChatAsReadByDoctorResponse = AxiosResponse<{success: boolean}>


export const apiSendChat = async (chatId: string, chat: { role: "user"|"assistant", content: string }[]): Promise<ChatResponse> => 
    await api.post(`/api/asa/chat/${chatId}`, { chat }, { headers: (await getAuthorization()) });

export const apiCreateAsaChat = async (): Promise<CreateAsaChatResponse> => 
    await api.post('/api/asa/chat/new', {}, { headers: await getAuthorization() });

export const apiDeleteAsaChat = async (chatId: string): Promise<DeleteAsaChatResponse> =>
    await api.delete(`/api/asa/chat/${chatId}`, { headers: await getAuthorization() });

export const apiGetAllAsaChats = async (): Promise<ChatListResponse> => 
    await api.get('/api/asa/chats', { headers: (await getAuthorization()) });


export const apiGetAsaChatMessages = async (chatId: string): Promise<AsaChatMessagesResponse> => 
    await api.get(`/api/asa/chat/${chatId}/messages`, { headers: (await getAuthorization()) });

export interface AsaChatsFilter {
    urgency_from?: number,
    urgency_to?: number,
    created_from?: number,
    created_to?: number,
    updated_from?: number
    updated_to?: number,
    limit?: number,
    read?: boolean,
    ssn?: string,
    startAfter?: string,
    sortBy?: "newest" | "oldest"
}

export const apiGetAsaChatsByUrgency = async (filter: AsaChatsFilter): Promise<AsaChatByUrgencyResponse> => {
    return await api.get('/api/asa/doctor/chats', {
        params: {
            filter,
        },
        headers: await getAuthorization(),
    });
};


export const apiMarkChatAsReadByDoctor = async (chatId: string): Promise<AsaMarkChatAsReadByDoctorResponse> =>
    await api.post(`/api/asa/chat/${chatId}/read`, {}, { headers: await getAuthorization() });