import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import Home from './pages/Home';
import Chat from './pages/Chat';
import DoctorList from './pages/DoctorsList';
import CreateDoctor from './pages/CreateDoctor';
import { AuthProvider } from './contexts/AuthContext';
import { LanguageProvider } from './contexts/LanguageContext'; // Import LanguageProvider
import ProtectedRoute from './components/ProtectedRoute';
import AdminRoute from './components/AdminRoute';
import ViewChats from './pages/ViewChats';
import ViewChat from './pages/ViewChat';
import UrgentChatsList from './pages/UrgentChatsList';
import ProfileView from './components/ProfileView'; // Import ProfileView
import PrescriptionsView from './components/PrescriptionsView'; // Import PrescriptionsView
import HistoryView from './components/HistoryView'; // Import HistoryView
import CertificatesView from './components/CertificatesView'; // Import CertificatesView
import DoctorsView from './components/DoctorsView'; // Import DoctorsView
import VaccinationsView from './components/VaccinationsView'; // Import VaccinationsView

// NEW IMPORT for Terms & Conditions
import TermsAndConditions from './pages/TermsAndConditions';
import Downtime from './pages/Downtime';

const App: React.FC = () => {
  return (
    <Router>
      <LanguageProvider>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/home"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="/chat/:chatId?"
              element={
                <ProtectedRoute>
                  <Chat />
                </ProtectedRoute>
              }
            />
            <Route
              path="/view-chats"
              element={
                <ProtectedRoute>
                  <ViewChats />
                </ProtectedRoute>
              }
            />
            <Route
              path="/view-urgent-chats"
              element={
                <ProtectedRoute>
                  <UrgentChatsList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/create-doctor"
              element={
                <AdminRoute>
                  <CreateDoctor />
                </AdminRoute>
              }
            />
            <Route
              path="/doctor-list"
              element={
                <AdminRoute>
                  <DoctorList />
                </AdminRoute>
              }
            />

            {/* Profile Route */}
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <ProfileView />
                </ProtectedRoute>
              }
            />

            {/* New Routes for Dropdown Menu Pages */}
            <Route
              path="/prescriptions"
              element={
                <ProtectedRoute>
                  <PrescriptionsView />
                </ProtectedRoute>
              }
            />
            <Route
              path="/history"
              element={
                <ProtectedRoute>
                  <HistoryView />
                </ProtectedRoute>
              }
            />
            <Route
              path="/certificates"
              element={
                <ProtectedRoute>
                  <CertificatesView />
                </ProtectedRoute>
              }
            />
            <Route
              path="/doctors"
              element={
                <ProtectedRoute>
                  <DoctorsView />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vaccinations"
              element={
                <ProtectedRoute>
                  <VaccinationsView />
                </ProtectedRoute>
              }
            />

            {/* NEW ROUTE for Terms & Conditions */}
            <Route path="/terms" element={<TermsAndConditions />} />

            {/* Catch-all route for invalid paths */}
            <Route path="*" element={<Login />} />
          </Routes>
        </AuthProvider>
      </LanguageProvider>
    </Router>
  );
};

export default App;
