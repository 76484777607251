// src/components/Chat.tsx

import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import ChatMessage from '../components/ChatMessage';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import {
  apiSendChat,
  apiCreateAsaChat,
  apiDeleteAsaChat,
  apiGetAllAsaChats,
  apiGetAsaChatMessages,
  RestrictedAsaChat,
} from '../api/asaChat';
import { AsaChatMessage } from '../api/asaChat';
import { serverTimestamp, Timestamp } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { FaDice, FaCircleNotch } from 'react-icons/fa'; // Combined imports
import { allInfoCards, InfoCardData } from './../data/infoCards'; // Import the cards
import { formatGptTextToJSX } from '../utils/formatGptTextToJSX';

// Import the profile image for Asa
const asaProfileImage = '/messageart.jpeg';

const Chat: React.FC = () => {
  const [messages, setMessages] = useState<AsaChatMessage[]>([]);
  const [input, setInput] = useState('');
  const [chats, setChats] = useState<RestrictedAsaChat[]>([]);
  const [isNextChatLoading, setIsNextChatLoading] = useState<boolean>(false);
  const [isCreatingChat, setIsCreatingChat] = useState<boolean>(false);
  const [currentChatId, setCurrentChatId] = useState<string | null>(null);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);
  const [visibleCards, setVisibleCards] = useState<InfoCardData[]>([]);
  const [shuffleKey, setShuffleKey] = useState(0); // Add shuffleKey state
  const auth = useContext(AuthContext);
  const params = useParams();
  const navigate = useNavigate();

  // **Added useEffect to update currentChatId based on URL params**
  useEffect(() => {
    console.log('Params updated: ', params);
    setCurrentChatId(params.chatId ? params.chatId : null);
  }, [params]);

  useEffect(() => {
    setVisibleCards(allInfoCards.slice(0, 4)); // Display the first four cards initially
  }, []);

  // Load all chats on component mount
  useEffect(() => {
    const loadChats = async () => {
      try {
        const res = await apiGetAllAsaChats();
        setChats(res.data.chats);
      } catch (error) {
        console.error('Failed to load chats:', error);
      }
    };
    loadChats();
  }, []);

  // Handle shuffling of cards
  const shuffleCards = () => {
    // Shuffle the cards
    const shuffled = [...allInfoCards].sort(() => Math.random() - 0.5);
    setVisibleCards(shuffled.slice(0, 4));

    // Update the shuffle key to force re-rendering of only the cards
    setShuffleKey((prevKey) => prevKey + 1);

    // Debug log to verify state changes
    console.log('Cards shuffled:', visibleCards);
    console.log('Shuffle key updated:', shuffleKey);
  };

  // Load chat messages when a chat is selected
  useEffect(() => {
    if (currentChatId) {
      const loadMessages = async () => {
        try {
          const res = await apiGetAsaChatMessages(currentChatId);
          setMessages(res.data.messages);
        } catch (error) {
          console.error('Failed to load chat messages:', error);
        }
      };
      loadMessages();
    } else {
      setMessages([]);
    }
  }, [currentChatId]);

  const handleSend = async () => {
    if (!input.trim() || !currentChatId) return;

    const newMessage = {
      role: 'user' as const,
      content: input,
      timestamp: serverTimestamp() as Timestamp,
    };
    setInput('');
    setMessages((prev) => [...prev, newMessage]);
    setIsNextChatLoading(true);

    try {
      const res = await apiSendChat(currentChatId, [...messages, newMessage]);
      const botMessage = {
        role: 'assistant' as const,
        content: res.data.message,
        timestamp: serverTimestamp() as Timestamp,
      };
      setMessages((prev) => [...prev, botMessage]);
    } catch (error) {
      console.error('Error sending message:', error);
    }
    setIsNextChatLoading(false);
  };

  const handleCreateChat = async () => {
    if (isCreatingChat || !auth || !auth.user) {
      console.log('Chat creation blocked due to state:', { isCreatingChat, auth });
      return;
    }

    setIsCreatingChat(true);
    try {
      const res = await apiCreateAsaChat();

      if (res.data.chatId) {
        navigate(`/chat/${res.data.chatId}`);
        // **Removed direct setCurrentChatId; it will be updated via useEffect**
        setMessages([]);
        setChats((prev) => [
          ...prev,
          {
            id: res.data.chatId,
            userId: auth.user?.id || '',
            diagnosis: '',
            urgency: 1,
            createdAt: new Date() as any,
            updatedAt: new Date() as any,
          },
        ]);
      } else {
        console.error('No chatId returned from API.');
      }
    } catch (error) {
      console.error('Error creating new chat:', error);
    } finally {
      setIsCreatingChat(false);
    }
  };

  const handleDeleteChat = async (chatId: string) => {
    try {
      await apiDeleteAsaChat(chatId);
      setChats((prev) => prev.filter((chat) => chat.id !== chatId));
      if (currentChatId === chatId) {
        setCurrentChatId(null);
        setMessages([]);
        navigate('/chat');
      }
    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSend();
    }
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed((collapsed) => !collapsed);
  };

  return (
    <div className="flex min-h-screen bg-gradient-to-br from-indigo-900 via-purple-900 to-blue-900 text-white relative">
      <Sidebar
        chats={chats}
        onSelectChat={(id) => navigate(`/chat/${id}`)}
        onDeleteChat={handleDeleteChat}
        isCollapsed={isSidebarCollapsed}
        toggleSidebar={toggleSidebar}
      />
      <div className="flex flex-col flex-1 transition-all duration-300">
        <Navbar isSidebarCollapsed={isSidebarCollapsed} toggleSidebar={toggleSidebar} />
        <div className="flex-1 flex flex-col items-center justify-center relative mt-12">
          {!currentChatId && (
            <div className="flex flex-col items-center gap-6 w-full max-w-5xl justify-center">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 px-8 w-full max-w-5xl justify-center">
                {visibleCards.map((card, index) => (
                  <InfoCard
                    key={`${shuffleKey}-${index}`}
                    bgColor={card.bgColor}
                    icon={card.icon}
                    title={card.title}
                    description={card.description}
                  />
                ))}
              </div>

              <button
                onClick={shuffleCards}
                className="p-2 bg-gradient-to-r from-gray-700 via-gray-800 to-gray-900 text-white rounded-full shadow-md transform hover:scale-110 transition-transform duration-200"
                aria-label="Shuffle Cards"
              >
                <FaDice className="h-5 w-5" />
              </button>
              <button
                onClick={handleCreateChat}
                className={`bg-gradient-to-r from-indigo-400 via-purple-500 to-blue-500 text-white text-2xl font-extrabold px-16 py-6 rounded-full shadow-lg transform hover:scale-105 transition-all duration-300 ${
                  isCreatingChat ? 'opacity-60 cursor-not-allowed' : ''
                }`}
                style={{
                  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.5)',
                  marginTop: '20px',
                }}
              >
                {isCreatingChat ? (
                  <FaCircleNotch className="animate-spin" size={36} color="#fff" />
                ) : (
                  <span>Hefja nýtt spjall</span>
                )}
              </button>
            </div>
          )}

          <div className="flex-1 p-6 overflow-auto w-full z-20">
            {messages.map((msg, index) => (
              <ChatMessage
                key={index}
                role={msg.role}
                content={formatGptTextToJSX(msg.content)}
                profileImage={msg.role === 'assistant' ? asaProfileImage : ''}
              />
            ))}
            {isNextChatLoading && (
              <ChatMessage
                key="loading"
                role="assistant"
                content={<PulseLoader className="p-2" />}
                profileImage={asaProfileImage}
              />
            )}
          </div>
        </div>

        {currentChatId && (
          <div className="p-4 bg-opacity-20 backdrop-blur-md border-t border-indigo-700 flex items-center shadow-md z-20">
            <input
              type="text"
              className="flex-1 border border-gray-700 rounded-full px-4 py-3 text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400 shadow-sm bg-transparent"
              placeholder="Spjalla við Ásu"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button
              onClick={handleSend}
              className="ml-4 bg-gradient-to-r from-indigo-400 to-purple-600 text-white px-5 py-3 rounded-full transform hover:scale-105 hover:from-indigo-500 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-400 shadow-lg"
            >
              Senda
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const InfoCard: React.FC<{ bgColor: string; icon: string; title: string; description: string }> = ({
  bgColor,
  icon,
  title,
  description,
}) => (
  <div
    className={`${bgColor} p-8 rounded-lg shadow-lg text-center w-full h-40 flex items-center justify-center transform hover:scale-105 transition-transform duration-500 border border-opacity-20 border-indigo-500 fade-in`}
  >
    <div>
      <p className="text-4xl mb-4 text-indigo-300">{icon}</p>
      <p className="font-bold mb-1 text-lg text-white">{title}</p>
      <p className="text-sm text-indigo-200">{description}</p>
    </div>
  </div>
);

export default Chat;
