import React from 'react';

interface DowntimeProps {
  bypassDowntime: () => void;
}

export const Downtime: React.FC<DowntimeProps> = ({bypassDowntime}) => {
  return (
    <div className="min-h-screen flex items-center justify-center subtle-moving-gradient relative">
      <div onClick={bypassDowntime} className="absolute top-4 right-4 w-24 h-24 cursor-pointer" />

      <div className="max-w-md w-full bg-opacity-80 bg-gray-900 p-8 rounded-lg shadow-lg transform transition-all duration-500 hover:scale-105">
        <div className="flex justify-center mb-6">
          <img src="/testlogo.png" alt="Logo" className="h-10 w-10 mr-3" />
        </div>
        <h2 className="text-3xl font-extrabold mb-6 text-white text-center tracking-wider">
          Ása er tímabundið niðri
        </h2>
      </div>
    </div>
  );
};

export default Downtime;
