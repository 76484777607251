import React, { useState, useEffect } from "react";
import { apiGetAsaChatMessages, AsaChatMessage } from "../api/asaChat";
import ChatMessage from "./ChatMessage";
import { PulseLoader } from "react-spinners";
import { formatGptTextToJSX } from "../utils/formatGptTextToJSX";

const asaProfileImage = '/messageart.jpeg';

interface ChatModalProps {
  chatId: string | null;
  onClose?: () => void;
}

export const ChatModal: React.FC<ChatModalProps> = ({ chatId, onClose }) => {

  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState<AsaChatMessage[]>([])

  useEffect(() => {
    const fetchChatMessages = async (id: string) => {
      try {
        setLoading(true);
        const data = await apiGetAsaChatMessages(id);
        setMessages(data.data.messages);
      } catch (error) {
        console.error('Error fetching chat messages:', error);
      } finally {
        setLoading(false);
      }
    };

    if (chatId) fetchChatMessages(chatId);
  }, [chatId]);

  if (!chatId) return null;

  return (
    <div
      className="
        fixed inset-0
        z-50
        flex items-center justify-center
        bg-black bg-opacity-50
      "
    >
      {/* Modal container */}
      <div
        className="
          relative
          w-full max-w-5xl
          h-fit
          max-h-[80vh]
          flex flex-col
          my-28
          bg-white
          rounded-md shadow-lg
          overflow-hidden
        "
      >
        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b border-gray-200">
          <h2 className="text-lg font-bold">Chat</h2>
          <button
            onClick={onClose}
            className="
              text-gray-400
              hover:text-gray-600
              transition-colors
              focus:outline-none
            "
          >
            <span className="sr-only">Close</span>
            &#x2715;
          </button>
        </div>

        {/* Chat content */}
        <div className="p-4 min-h-[200px] h-full overflow-y-auto">
          {loading ? (
            <div className="flex justify-center p-4">
              <PulseLoader />
            </div>
          ) : messages.length === 0 ? (
            <div className="text-center text-gray-500">No messages found.</div>
          ) : (
            messages.map((msg, index) => (
              <ChatMessage
                key={index}
                role={msg.role}
                content={formatGptTextToJSX(msg.content)}
                profileImage={msg.role === 'assistant' ? asaProfileImage : ''}
              />
            ))
          )}
        </div>
      </div>
    </div>
  )
}