import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../contexts/LanguageContext';

const TermsAndConditions: React.FC = () => {
  const navigate = useNavigate();
  const languageContext = useContext(LanguageContext);

  if (!languageContext) {
    throw new Error('LanguageContext must be used within a LanguageProvider');
  }

  const { language } = languageContext;

  // -----------------------------------------
  // Icelandic Terms & Conditions
  // -----------------------------------------
  const icelandicTandC = (
    <div className="space-y-6 leading-relaxed text-lg">
      <p className="text-pink-300 font-semibold">
        Þetta forrit notar gervigreind, nefnd <strong>Asa</strong>, til að aðstoða við
        frumgreiningar og forúttektir á sjúklingum fyrir heilsugæslu á Íslandi. Asa er
        hönnuð til að safna upplýsingum og gefa tillögur að mögulegum greiningum auk þess
        að meta bráðaþörf sjúklinga. Vinsamlegast athugið eftirfarandi:
      </p>
      <ul className="list-disc list-inside space-y-2 text-white">
        <li>
          <strong>Asa er ekki löggiltur heilbrigðisstarfsmaður.</strong> Hlutverk Asa er að
          aðstoða heilbrigðisstarfsmenn með því að safna viðeigandi upplýsingum og veita
          frumgreiningu.
        </li>
        <li>
          <strong>Niðurstöður og greiningar Asa eru eingöngu tillögur</strong> og ætti ekki að
          líta á þær sem endanlegar. Allar læknisfræðilegar ráðleggingar og meðferðir þurfa að
          staðfestast af löggiltum heilbrigðisstarfsmanni.
        </li>
        <li>
          <strong>Gervigreindin kemur ekki í staðinn fyrir faglega læknisráðgjöf, greiningu eða meðferð.</strong>
          Leitaðu alltaf til faglegs heilbrigðisstarfsmanns með öll læknisfræðileg vandamál eða ákvarðanir.
        </li>
        <li>
          <strong>Trúnaður:</strong> Það getur verið mikilvægt að viðkvæmar upplýsingar komi fram í
          spjallinu til að tryggja rétta greiningu og meðferð. Upplýsingarnar sem deilt er með Asa
          eru meðhöndlaðar af ábyrgð, og aðeins heilbrigðisstarfsmenn sem þurfa að fara yfir mál þitt
          hafa aðgang að spjallinu. Fylgdu ávallt leiðbeiningum heilsugæslunnar varðandi friðhelgi
          og vernd gagna.
        </li>
        <li>
          <strong>Stuðningur við tungumál:</strong> Asa mun svara á því tungumáli sem talað er við hana á
          og er hönnuð til að spyrja viðbótarspurninga til að fá betri skilning á ástandi þínu. Hins vegar
          geta svör verið breytileg og ekki öll tungumál eru studd með fullri nákvæmni.
        </li>
        <li>
          <strong>Takmarkanir:</strong> Asa er hönnuð til að hjálpa við forgangsröðun heilbrigðisþjónustu
          en getur ekki sinnt neyðartilvikum. Í neyðartilvikum skaltu tafarlaust leita aðstoðar frá
          neyðarþjónustu eða heimsækja næsta heilsugæslustofnun.
        </li>
      </ul>
      <p className="text-pink-300 font-semibold">
        Með því að nota þetta forrit samþykkir þú þessa skilmála og viðurkennir þær takmarkanir sem fylgja
        þjónustu Asa.
      </p>
    </div>
  );

  // -----------------------------------------
  // English Terms & Conditions (Placeholder)
  // -----------------------------------------
  const englishTandC = (
    <div className="space-y-6 leading-relaxed text-lg">
      <p className="text-pink-300 font-semibold">
        English Terms &amp; Conditions (Placeholder)
      </p>
      <p className="text-white">
        Replace this with your actual English Terms &amp; Conditions text...
      </p>
    </div>
  );

  // -----------------------------------------
  // Polish Terms & Conditions (Placeholder)
  // -----------------------------------------
  const polishTandC = (
    <div className="space-y-6 leading-relaxed text-lg">
      <p className="text-pink-300 font-semibold">
        Polskie Warunki Korzystania (Placeholder)
      </p>
      <p className="text-white">
        Tutaj możesz wstawić właściwy tekst warunków w języku polskim...
      </p>
    </div>
  );

  // Decide which T&C to show based on the language
  const renderedTandC = (() => {
    switch (language) {
      case 'is':
        return icelandicTandC;
      case 'pl':
        return polishTandC;
      default:
        return englishTandC;
    }
  })();

  // Back handler
  const handleBack = () => {
    navigate(-1); // or navigate('/somewhere')
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-900 via-black to-gray-900 text-white flex flex-col items-center relative">
      {/* Fixed 'Back' button in the top-left corner */}
      <div className="absolute top-6 left-6 z-50">
        <button
          onClick={handleBack}
          className="p-2 rounded-full bg-gradient-to-r from-pink-400 to-purple-500
            hover:from-pink-500 hover:to-purple-600 shadow-lg
            transition-transform duration-300 ease-in-out transform hover:scale-110"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-6 w-6 text-white"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </button>
      </div>

      {/* Main content container */}
      <div className="w-full max-w-5xl p-8 sm:p-12 flex flex-col justify-center items-center space-y-10">
        {/* Heading */}
        <h2 className="text-4xl sm:text-5xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-pink-400 via-purple-500 to-blue-500 text-center leading-tight">
          {language === 'is'
            ? 'Skilmálar'
            : language === 'pl'
            ? 'Warunki'
            : 'Terms & Conditions'}
        </h2>

        {/* Terms & Conditions Text */}
        <div className="bg-gray-800 bg-opacity-70 w-full p-6 sm:p-8 rounded-lg shadow-xl">
          {renderedTandC}
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
