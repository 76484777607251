import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { LanguageContext } from '../contexts/LanguageContext';

const Register: React.FC = () => {
  const minStep = 1;
  const maxStep = 3;
  const [step, setStep] = useState(1);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullname, setFullname] = useState('');
  const [ssn, setSSN] = useState('');
  const role = 'user';

  // NEW: track whether user has agreed to T&C
  const [hasAgreed, setHasAgreed] = useState(false);

  const auth = useContext(AuthContext);
  const languageContext = useContext(LanguageContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  if (!languageContext) {
    throw new Error('LanguageContext must be used within a LanguageProvider');
  }
  
  const { language, setLanguage } = languageContext;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (step < maxStep) {
      nextStep();
    } else {
      // Final step: call your registration logic
      if (auth) {
        await auth.register(email, password, fullname, role, ssn);
      }
    }
  };

  const formatSSN = (value: string) => {
    const digitsOnly = value.replace(/\D/g, '');
    if (digitsOnly.length > 6) {
      return `${digitsOnly.slice(0, 6)}-${digitsOnly.slice(6, 10)}`;
    }
    return digitsOnly;
  };

  const handleSSNChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedSSN = formatSSN(e.target.value);
    setSSN(formattedSSN);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-300">
              {language === 'en'
                ? 'Full Name'
                : language === 'is'
                ? 'Fullt nafn'
                : 'Imię i nazwisko'}
            </label>
            <input
              type="text"
              className="w-full px-4 py-2 mt-2 text-gray-900 border border-transparent rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent shadow-sm"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
              required
            />
            <button
              type="button"
              className="w-full mt-4 py-2 px-4 bg-blue-600 text-white font-bold rounded-lg transition-colors duration-300 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              onClick={nextStep}
            >
              {language === 'en'
                ? 'Next'
                : language === 'is'
                ? 'Næst'
                : 'Dalej'}
            </button>
          </div>
        );
      case 2:
        return (
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-300">
              {language === 'en'
                ? 'Email'
                : language === 'is'
                ? 'Netfang'
                : 'Email'}
            </label>
            <input
              type="email"
              className="w-full px-4 py-2 mt-2 text-gray-900 border border-transparent rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent shadow-sm"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <div className="flex justify-between mt-4">
              <button
                type="button"
                className="py-2 px-4 bg-gray-500 text-white font-bold rounded-lg transition-colors duration-300 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
                onClick={prevStep}
              >
                {language === 'en'
                  ? 'Back'
                  : language === 'is'
                  ? 'Til baka'
                  : 'Wstecz'}
              </button>
              <button
                type="button"
                className="py-2 px-4 bg-blue-600 text-white font-bold rounded-lg transition-colors duration-300 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                onClick={nextStep}
              >
                {language === 'en'
                  ? 'Next'
                  : language === 'is'
                  ? 'Næst'
                  : 'Dalej'}
              </button>
            </div>
          </div>
        );
      case 3:
        return (
          <>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-300">
                {language === 'en'
                  ? 'Password'
                  : language === 'is'
                  ? 'Lykilorð'
                  : 'Hasło'}
              </label>
              <input
                type="password"
                className="w-full px-4 py-2 mt-2 text-gray-900 border border-transparent rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent shadow-sm"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-300">
                {language === 'en'
                  ? 'Social Security Number'
                  : language === 'is'
                  ? 'Kennitala'
                  : 'Numer PESEL'}
              </label>
              <input
                type="text"
                className="w-full px-4 py-2 mt-2 text-gray-900 border border-transparent rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent shadow-sm"
                value={ssn}
                onChange={handleSSNChange}
                required
              />
            </div>

            {/* NEW: Terms & Conditions Agreement Section */}
            <div className="mb-4">
              <label className="inline-flex items-center text-gray-300 text-sm">
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-blue-500"
                  checked={hasAgreed}
                  onChange={(e) => setHasAgreed(e.target.checked)}
                />
                <span className="ml-2">
                  {language === 'en'
                    ? 'I accept the '
                    : language === 'is'
                    ? 'Ég samþykki '
                    : 'Akceptuję '}
                  <Link to="/terms" className="text-blue-400 hover:underline">
                    {language === 'en'
                      ? 'Terms & Conditions'
                      : language === 'is'
                      ? 'Skilmála'
                      : 'Regulamin'}
                  </Link>
                </span>
              </label>
            </div>

            <div className="flex justify-between mt-4">
              <button
                type="button"
                className="py-2 px-4 bg-gray-500 text-white font-bold rounded-lg transition-colors duration-300 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
                onClick={prevStep}
              >
                {language === 'en'
                  ? 'Back'
                  : language === 'is'
                  ? 'Til baka'
                  : 'Wstecz'}
              </button>
              <button
                type="submit"
                disabled={!hasAgreed} // Disable if T&C not agreed
                className={`py-2 px-4 ${
                  hasAgreed
                    ? 'bg-blue-600 hover:bg-blue-700'
                    : 'bg-gray-400 cursor-not-allowed'
                } text-white font-bold rounded-lg transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
              >
                {language === 'en'
                  ? 'Register'
                  : language === 'is'
                  ? 'Skrá'
                  : 'Zarejestruj się'}
              </button>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const changeLanguage = (lang: string) => {
    setLanguage(lang);
    setIsMenuOpen(false);
  };

  const getLanguageFlag = () => {
    switch (language) {
      case 'en':
        return '🇬🇧';
      case 'is':
        return '🇮🇸';
      case 'pl':
        return '🇵🇱';
      default:
        return '🌐';
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center subtle-moving-gradient relative">
      <div className="absolute top-4 right-4" ref={menuRef}>
        <div className="relative">
          <button
            className="text-white focus:outline-none text-3xl"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {getLanguageFlag()}
          </button>
          {isMenuOpen && (
            <div className="absolute right-0 mt-2 w-56 bg-white rounded-md shadow-lg py-2 z-20">
              <button
                onClick={() => changeLanguage('en')}
                className="block w-full text-left px-6 py-3 text-lg text-gray-700 hover:bg-gray-100"
              >
                🇬🇧 English
              </button>
              <button
                onClick={() => changeLanguage('is')}
                className="block w-full text-left px-6 py-3 text-lg text-gray-700 hover:bg-gray-100"
              >
                🇮🇸 Íslenska
              </button>
              <button
                onClick={() => changeLanguage('pl')}
                className="block w-full text-left px-6 py-3 text-lg text-gray-700 hover:bg-gray-100"
              >
                🇵🇱 Polski
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="max-w-md w-full bg-opacity-80 bg-gray-900 p-8 rounded-lg shadow-lg transform transition-all duration-500 hover:scale-105">
        <div className="flex justify-center mb-6">
          <img src="/testlogo.png" alt="Logo" className="h-10 w-10 mr-3" />
        </div>
        <h2 className="text-3xl font-extrabold mb-6 text-white text-center tracking-wider">
          {language === 'en'
            ? 'Register'
            : language === 'is'
            ? 'Skrá'
            : 'Zarejestruj się'}
        </h2>
        <form onSubmit={handleSubmit}>
          {renderStep()}
        </form>
        {step === 1 && (
          <p className="mt-6 text-center text-gray-400">
            {language === 'en'
              ? 'Already have an account?'
              : language === 'is'
              ? 'Ertu með aðgang?'
              : 'Masz już konto?'}{' '}
            <Link
              to="/login"
              className="text-blue-400 hover:underline transition-all duration-200"
            >
              {language === 'en'
                ? 'Login'
                : language === 'is'
                ? 'Innskrá'
                : 'Zaloguj się'}
            </Link>
          </p>
        )}
      </div>
    </div>
  );
};

export default Register;
